@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "swiper/css";
@import "swiper/css/navigation";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400&display=swap");

.swiper-button-next,
.swiper-button-prev {
  color: white;
}

html,
body {
  height: 100%;
}

/* WorksGrid.css */
.masonry-grid {
  display: flex;
  margin-left: -20px; /* Gestisce il margine negativo tra le colonne */
  width: auto;
}

.masonry-grid-column {
  padding-left: 20px; /* Spazio tra le colonne */
  background-clip: padding-box;
}

.marquee-content::before {
  content: "";
  display: inline-block;
  width: 0.2em; /* Regola la larghezza dello spazio in base al tuo design */
}

.marquee-content-last::before {
  content: "";
  display: inline-block;
  width: 0.1em; /* Regola la larghezza dello spazio in base al tuo design */
}

@keyframes slideInLeft {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInTop {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInBottom {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slideInLeft {
  animation: slideInLeft 1.5s forwards;
}

.animate-slideInTop {
  animation: slideInTop 1.5s forwards;
}

.animate-slideInBottom {
  animation: slideInBottom 1.5s forwards;
}

.animate-slideInRight {
  animation: slideInRight 1.5s forwards;
}
